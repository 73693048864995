@use "../../styles/global" as *;
@use "../../styles/partials/variables" as *;

.venue-request {
  @extend .general-tab;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1vw 2vw 1vw 1vw;

    &-left {
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 2.5vw;
      }

      &-heading {
        @extend .page-headings;
      }
    }

    &-button {
      display: flex;
      align-items: center;

      background-color: transparent;
      color: $white;
      font-size: 1.3rem;

      border-radius: 8px;
      border: 2px solid $white;

      padding: 1vw 2vw;
    }
  }

  &__button-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    gap: 8px;

    padding: 0 1vw;

    > button {
      max-width: max-content;
      border-radius: 8px;

      outline: none;

      color: #FFF;
      font-family: 'Inter', sans-serif;
      font-size: 18px;
      font-weight: 500;

      padding: 16px 24px;
    }

    &__accept {
      background: linear-gradient(271deg, #7229F5 1.5%, #0A3FD6 98.6%);
      border: none;

      height: 55px;
    }

    &__deny {
      background-color: transparent;
      border: solid 1px white;
    }
  }
}
