@use "styles/partials/variables" as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: "Inter";

  outline: none;
}

body{
  background: #000000;
}

.main-container {
  min-height: 100vh;
  width: 100%;

  display: flex;
}
