@use "../../styles/global" as *;
@use "../../styles//partials/variables" as *;

.add-venue {
  @extend .general-tab;

  &__hours-of-operation {
    &__first-hour > button,
    &__additional-hours > button {
      width: 44px;
      height: 44px;

      text-align: center;

      background-color: #ffffff1a;

      font-size: 24px;
      font-weight: 700;

      border: 0;
      outline: 0;

      color: #fff;

      border-radius: 8px;

      cursor: pointer;

      &:active {
        opacity: 0.4;
      }
    }

    &__first-hour {
      flex: 1;

      display: flex;
      align-items: center;
      gap: 6px;

      > div {
        flex: 1;
      }
    }

    &__additional-hours {
      display: flex;
      align-items: center;
      gap: 6px;

      > button {
        color: #f00;
      }

      margin-top: 20px;

      > div {
        flex: 1;
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;

    margin-bottom: 2vw;

    &-icon {
      width: 2vw;
      height: 2vw;

      margin-right: 1.5vw;
    }

    &-heading {
      @extend .page-headings;
    }
  }

  &__form {
    width: 100%;

    display: flex;

    color: $white;

    &--left {
      width: 100%;
    }

    &--right {
      padding: 2vw 0 0 6vw;
    }

    &-block {
      display: flex;
      gap: 20px;
      align-items: center;

      flex-wrap: wrap;

      &-group {
        display: flex;
        flex-direction: column;

        width: 45%;

        margin-bottom: 2vw;

        color: $white;

        &-label {
          padding: 0.5vw 2vw;
        }

        &-input {
          background-color: rgba($grey, 0.1);
          color: $white;

          outline: none;
          border: none;
          border-radius: 8px;

          padding: 1vw 2vw;

          &--special {
            display: flex;
            justify-content: space-between;
            padding-right: 1vw;
          }

          &-text {
            font-size: 0.8rem;
            font-weight: 300;

            color: rgba($white, 0.2);
          }

          &::placeholder {
            color: rgba($white, 0.5);
          }
        }
      }

      &-about {
        display: flex;
        flex-direction: column;

        width: 100%;

        margin-bottom: 2vw;

        &-input {
          background-color: rgba($grey, 0.1);
          color: $white;

          outline: none;
          border: none;
          border-radius: 8px;

          padding: 1vw;

          resize: none;

          // height: 8vw;
        }
      }
    }

    &-photo {
      background-color: rgba($white, 0.1);
      border: none;
      border-radius: 8px;

      width: 10vw;
      height: 10vw;

      margin-bottom: 40px;

      &-button {
        background-color: transparent;
        border: none;

        width: 100%;
        height: 100%;

        cursor: pointer;
      }
    }
  }
}
