@use "../../styles/global" as *;
@use "../../styles//partials/variables" as *;

.ad-management {
  @extend .general-tab;

  &__top {
    @extend .tab-top;

    &-heading {
      @extend .page-headings;
    }

    &-clear {
      @extend .clear-button;

      &-icon {
        padding-left: 1vw;
      }
    }
  }

  &__tabs {
    width: 100%;
    border-bottom: 1px solid rgba($white, 0.1);

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: $white;

    cursor: pointer;

    &-link {
      width: 50%;
      text-decoration: none;

      padding: 1vw 0;

      display: flex;
      justify-content: center;
      align-items: center;

      &--active {
        border-bottom: 1px solid $general-purple;
      }
    }
  }
}
