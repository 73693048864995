@use "../../styles/partials/variables" as *;
@use "../../styles/global" as *;

.terms {
  padding: 0 2vw;
  height: 100%;

  &__block {
    margin-bottom: 2vw;

    &-writing {
      font-weight: 200;

      color: $white;
    }
  }
}

.container-tab {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 32px;

  > button {
    border: 1px solid transparent;
    background: transparent;
    font-size: 18px;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    line-height: 21.6px;
    padding: 0px 12px 8px 12px;
    width: 100%;
    cursor: pointer;

    &.active-tab {
      border-bottom: 1px solid rgba(167, 41, 245, 1);
    }
  }
}
