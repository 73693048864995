@use "partials/typography" as *;
@use "partials/variables" as *;

main {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
}

button {
  cursor: pointer;
}

.general-tab {
  margin: 10vh 16px 2vw 23px;
  padding: 2vw;

  background-color: rgba($white, 0.1);
  border-radius: 20px;

  width: 95%;
  height: fit-content;
}

.clear-button {
  background-color: transparent;
  color: $white;
  font-size: 1.1rem;
  font-weight: 400;

  border-radius: 8px;
  border: 2px solid $white;

  display: flex;
  justify-content: space-between;

  padding: 10px 12px 10px 12px;

  &:hover {
    background-color: rgba($white, 0.2);
  }

  &:active {
    background-color: rgba($background-blue, 0.5);
    border: 2px solid rgba($background-purple, 0.9);
  }
}

.colorful-button {
  background: linear-gradient(120deg, $button-blue 30%, $button-purple 100%);

  border: none;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;

  padding: 16px 24px 16px 24px;

  color: $white;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.sort-buttons {
  background-color: rgba($grey, 0.1);

  border-radius: 40px;
  border: none;

  display: flex;
  justify-content: space-between;

  padding: 0.5vw 1vw;
  margin-right: 1vw;

  &:hover {
    background-color: $button-sort-hover;
  }

  &:active {
    background-color: $button-sort-active;
  }
}

.sort-buttons-text {
  color: $white;
  font-size: 1rem;
  font-weight: 200;
}

.tab-top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1vw 0;
}

.page-headings {
  font-size: 2rem;
  font-weight: 400;

  color: $white;
}

.table-headings {
  font-size: 1.1rem;
  font-weight: 300;

  border-bottom: 1px solid rgba($white, 0.2);

  padding: 1vw 0;

  color: $white;
}

.table-info {
  font-size: 0.9rem;
  font-weight: 200;

  padding: 0.7vw 0;
  text-align: center;

  color: $white;

  > a {
    color: $white;
  }
}

.search {
  background-color: rgba($white, 0.1);
  width: 15vw;
  height: 2.5vw;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-right: 2vw;
  padding: 0 0.5vw;

  border-radius: 8px;

  &-icon {
    margin-right: 0.5vw;
  }

  &-input {
    background-color: transparent;
    border: none;
    outline: none;

    font-weight: 100;
  }

  &-x {
    background-color: rgba($white, 0.15);
    border-radius: 50%;

    width: 1vw;
    height: 1vw;

    display: flex;
    justify-content: center;
    align-items: center;

    &-text {
      font-size: 0.7rem;
      font-weight: 200;

      color: rgba($white, 0.4);
    }
  }
}

.slide-button {
  width: 60px;
  height: 30px;

  background-color: #ccc;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slider {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  transition: transform 0.3s;
}

.slide-button.checked {
  background-color: $general-purple;
}

.slide-button.checked .slider {
  transform: translateX(30px);
}

.pagination {
  margin-top: 4vw;
  display: flex;
  justify-content: flex-end;
  list-style-type: none;

  gap: 8px;

  > li {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 36px;
    height: 36px;

    color: #fff;

    border-radius: 80px;

    cursor: pointer;

    border: 1px solid rgba(255, 255, 255, 0.1);

    font-weight: 700;

    > a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & + .next {
      display: none;
    }
  }
  .selected {
    background: rgba(255, 255, 255, 0.1);
  }

  .previous.disabled {
    display: none;
  }
  .previous {
    display: none;
  }
  .active-number {
    background: rgba(255, 255, 255, 0.1);
  }
}

th,
td {
  text-align: left !important;
}
