@use '../../styles/global' as *;
@use '../../styles/partials/variables' as *;

.no-checks {
    width: 100%;

    background-color: rgba($white, 0.2);

    border-radius: 20px;

    margin-top: 2vw;
    padding: 2vw 1vw;

    display: flex;
    justify-content: center;
    align-items: center;
}

.no-checks {
    @extend .no-checks;
}