@use "../../styles/partials/variables" as *;
@use "../../styles/global" as *;

.privacy {
  padding: 0 2vw;

  &__field {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 45%;

    padding: 1vw 0;

    &-text {
      color: $white;

      font-size: 1.1rem;
      font-weight: 200;
    }
  }
}

.container-password {
  display: flex;

  flex-direction: column;

  gap: 12px;

  margin-top: 20px;
}

.button-password {
  background: linear-gradient(120deg, #0a3fd6 30%, #7229f5 100%);
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;
  padding: 10px 12px 10px 12px;
  color: #ffffff;

  width: max-content;
}

.change-password {
  display: flex;

  > h1 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 15px;
  }
}
