@use "../../../styles/global" as *;
@use "../../../styles/partials/variables" as *;

.past {
  margin: 10vh 16px 2vw 23px;
  padding: 2vw;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  width: 95%;
  height: -moz-fit-content;
  height: fit-content;

  &__sorts {
    margin: 2vw 0;

    display: flex;

    &-button {
      @extend .sort-buttons;

      &-text {
        @extend .sort-buttons-text;
      }

      &-icon {
        padding-left: 0.8vw;
      }
    }

    &-purple {
      background-color: transparent;
      border: none;

      color: $button-purple;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    &-top {
      th {
        width: 7.2%;

        &:nth-child(1),
        &:nth-child(5),
        &:nth-child(8) {
          width: 13%;
        }
      }

      &-headings {
        &-heading {
          @extend .table-headings;
        }
      }
    }

    &-info {
      &-info {
        border-bottom: $table-info-bottom;

        &:hover {
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
        }

        &-text {
          @extend .table-info;

          &-icon {
            margin-left: 0.5vw;

            width: 0.9vw;
            height: 0.9vw;

            opacity: 0;
          }

          &-link {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          &--special {
            display: flex;
          }
        }

        &:hover &-text-icon {
          opacity: 1;
        }
      }
    }
  }
}
.input-search {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 13px 16px;
  min-height: 38px;
}
