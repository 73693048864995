@use "../../styles/global" as *;
@use "../../styles//partials/variables" as *;

.add-event {
  @extend .general-tab;

  &__top {
    display: flex;
    align-items: center;

    margin-bottom: 2vw;

    &-icon {
      width: 2vw;
      height: 2vw;

      margin-right: 1.5vw;
    }

    &-heading {
      @extend .page-headings;
    }
  }

  &__form {
    width: 100%;

    display: flex;

    color: $white;

    &--left {
      width: 100%;
    }

    &--right {
      padding: 2vw 0 0 6vw;
    }

    &-block {
      display: flex;
      // justify-content: space-between;
      align-items: center;

      gap: 20px;

      &-group {
        display: flex;
        flex-direction: column;

        width: 45%;

        margin-bottom: 2vw;

        &-label {
          padding: 0.5vw 2vw;
        }

        &-input {
          background-color: rgba($grey, 0.1);
          color: $white;

          outline: none;
          border: none;
          border-radius: 8px;

          padding: 1vw 2vw;

          &:focus {
            border: 1px solid $general-purple;
          }

          &--special {
            display: flex;
            justify-content: space-between;
            padding-right: 1vw;
          }

          &-text {
            font-size: 0.8rem;
            font-weight: 300;

            color: rgba($white, 0.2);
          }

          &::placeholder {
            color: rgba($white, 0.5);
          }
        }

        &-container {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &--calendar {
            margin-left: 2vw;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      &-about {
        display: flex;
        flex-direction: column;

        width: 100%;

        margin-bottom: 2vw;

        &-input {
          background-color: rgba($grey, 0.1);
          color: $white;

          outline: none;
          border: none;
          border-radius: 8px;

          padding: 1vw;

          resize: none;

          // height: 8vw;
        }
      }
    }

    &-button {
      background: linear-gradient(90deg, $button-purple 0%, $button-blue 100%);

      border: none;
      border-radius: 8px;

      display: flex;

      padding: 1vw;

      &-text {
        color: $white;
        font-size: 1rem;

        margin-right: 1vw;
      }
    }

    &-photo {
      &-button {
        background-color: transparent;
        border: none;

        width: 100%;
        height: 100%;

        cursor: pointer;
      }
    }
  }
}
