@use "../../styles/partials/variables" as *;

.sidebar {
  height: auto;
  max-width: 100%;

  background-color: rgba($white, 0.1);

  color: $white;

  padding-top: 10vh;

  width: 245px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    border: 2px solid transparent;

    padding: 16px 12px 16px 32px;

    gap: 20px;

    &:hover {
      border: 2px solid $white;
      border-radius: 8px;
    }

    &-left {
      display: flex;
      align-items: center;

      gap: 14px;

      &-icon {
        width: 24px;
        height: 24px;
      }

      &-writing {
        font-weight: 500;

        color: $white;

        font-size: 14px;

        line-height: 16.94px;
      }
    }
  }
}

.active {
  background: linear-gradient(90deg, $button-purple 0%, $button-blue 100%);

  border-radius: 8px;
}
