@use "../../styles/partials/variables" as *;
@use "../../styles/global" as *;

.promos {
  @extend .general-tab;

  &__top {
    @extend .tab-top;

    &-heading {
      @extend .page-headings;
    }

    &-right {
      display: flex;
      align-items: center;

      &-search {
        @extend .search;

        &-icon {
          @extend .search-icon;
        }

        &-input {
          @extend .search-input;
        }

        &-x {
          @extend .search-x;

          &-text {
            @extend .search-x-text;
          }
        }
      }

      &-colorful {
        @extend .colorful-button;

        margin-right: 2vw;
      }

      &-clear {
        @extend .clear-button;

        &-icon {
          padding-left: 1vw;
        }
      }
    }
  }

  &__sub-nav {
    width: 100%;
    border-bottom: 1px solid rgba($white, 0.1);

    display: flex;
    justify-content: space-between;
    align-items: center;

    &-link {
      width: 50%;
      text-decoration: none;

      padding: 1vw 0;

      display: flex;
      justify-content: center;
      align-items: center;

      &--active {
        border-bottom: 1px solid $general-purple;
      }
    }
  }
}
