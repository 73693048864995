@use "../../styles/global" as *;
@use "../../styles/partials/variables" as *;

.venue-action-button {
  display: flex;
  align-items: center;
  padding: 10px 12px 10px 12px;
  font-size: 1.1rem;
  border-radius: 8px;
  border: 2px solid;
  color: #fff;
}

.accept {
  background-color: green;
  border-color: green;
}

.deny {
  background-color: red;
  border-color: red;
}

.edit-venue {
  @extend .general-tab;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1vw 2vw 1vw 1vw;

    &-left {
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 2.5vw;
      }

      &-heading {
        @extend .page-headings;
      }
    }

    &-button {
      @extend .clear-button;
      align-items: center;

      &-text {
        margin-right: 1vw;
      }
    }
  
    &-button-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      &__accept-button {
        
      }

      &__decline-button {
        display: flex;
        align-items: center;
        border: 2px solid red;
        color: red !important;
        padding: 10px 12px 10px 12px;
        font-size: 1.1rem;
        color: #fff;
        border-radius: 8px;
        background-color: transparent;
      }
    }
  }

  &__sub-nav {
    width: 100%;
    border-bottom: 1px solid rgba($white, 0.1);

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: $white;

    &-link {
      width: 50%;
      text-decoration: none;

      padding: 1vw 0;

      display: flex;
      justify-content: center;
      align-items: center;

      &--active {
        border-bottom: 1px solid $general-purple;
      }
    }
  }
}
