@use "../../styles/partials/variables" as *;
@use "../../styles/global" as *;

.venues {
  @extend .general-tab;

  &__top {
    @extend .tab-top;

    &-heading {
      @extend .page-headings;
    }

    &-buttons {
      display: flex;
      align-items: center;

      &-search {
        @extend .search;

        &-icon {
          @extend .search-icon;
        }

        &-input {
          @extend .search-input;
        }

        &-x {
          @extend .search-x;

          &-text {
            @extend .search-x-text;
          }
        }
      }

      &-colorful {
        @extend .colorful-button;

        margin-right: 2vw;
      }

      &-clear {
        @extend .clear-button;

        &-text {
          margin-right: 1vw;
        }
      }
    }
  }

  &__sorts {
    margin-bottom: 2vw;

    display: flex;

    &-button {
      @extend .sort-buttons;

      &-text {
        @extend .sort-buttons-text;
      }

      &-icon {
        padding-left: 0.8vw;
      }
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    &-top {
      th {
        width: 10%;

        &:nth-child(3),
        &:nth-child(7) {
          width: 15%;
        }
      }

      &-headings {
        &-heading {
          @extend .table-headings;
        }
      }
    }

    &-info {
      &-info {
        border-bottom: $table-info-bottom;

        &:hover {
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
        }

        &-text {
          @extend .table-info;

          &-icon {
            margin-left: 0.5vw;

            width: 0.9vw;
            height: 0.9vw;

            opacity: 0;
          }

          &--special {
            display: flex;
            justify-content: center;
          }

          &--extra {
            text-align: center;
          }
        }

        &:hover &-text-icon {
          opacity: 1;
        }
      }
    }
  }
}

.truncated {
  width: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pagination {
  margin-top: 4vw;
  display: flex;
  justify-content: flex-end;
  list-style-type: none;

  > li {
    color: $white;
  }
}

.page-number {
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 15px;

  margin: 0 0.5vw;

  color: $white;

  &-next {
    color: $white;
  }
  &-previous {
    color: $white;
  }
}

.active-number {
  color: $white;

  background-color: rgba(255, 255, 255, 0.1);
}

.add-business {
  background: linear-gradient(120deg, #0a3fd6 30%, #7229f5 100%);
  border: none !important;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;
  padding: 10px 12px 10px 12px;
  color: #ffffff;
}
