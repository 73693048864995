@font-face {
    font-family: "Inter";
    src: url('../../assets/fonts/inter/Inter-ExtraLight.ttf') format('TrueType');
    font-weight: 100;
}

@font-face {
    font-family: "Inter";
    src: url('../../assets/fonts/inter/Inter-Regular.ttf') format('TrueType');
    font-weight: 300;
}

@font-face {
    font-family: "Inter";
    src: url('../../assets/fonts/inter/Inter-SemiBold.ttf') format('TrueType');
    font-weight: 400;
}

@font-face {
    font-family: "Inter";
    src: url('../../assets/fonts/inter/Inter-Bold.ttf') format('TrueType');
    font-weight: 600;
}

@font-face {
    font-family: "Inter";
    src: url('../../assets/fonts/inter/Inter-ExtraBold.ttf') format('TrueType');
    font-weight: 800;
}