@use "../../styles/global" as *;
@use "../../styles//partials/variables" as *;

.upcoming {
  &__sorts {
    margin: 2vw 0;

    display: flex;

    &-button {
      @extend .sort-buttons;

      &-text {
        @extend .sort-buttons-text;
      }

      &-icon {
        padding-left: 0.8vw;
      }
    }

    &-purple {
      background-color: transparent;
      border: none;

      color: $button-purple;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    &-top {
      th {
        width: 7.2%;

        &:nth-child(1),
        &:nth-child(5),
        &:nth-child(8) {
          width: 13%;
        }
      }

      &-headings {
        &-heading {
          @extend .table-headings;
        }
      }
    }

    &-info {
      &-info {
        border-bottom: $table-info-bottom;

        &:hover {
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
        }

        &-text {
          @extend .table-info;

          &-icon {
            margin-left: 0.5vw;

            width: 0.9vw;
            height: 0.9vw;

            opacity: 0;
          }

          &-link {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          &--special {
            display: flex;
          }
        }

        &:hover &-text-icon {
          opacity: 1;
        }
      }
    }
  }
}

.no-data-message {
  > td {
    text-align: center !important;
  }
}
