@use "../../styles/partials/variables" as *;

#two-f-a {
  height: 100vh;
  width: 100vw;

  background: linear-gradient(
    0deg,
    $background-black 0%,
    $background-darkblue 70%,
    $background-blue 100%
  );

  &, form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .react-code-input {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  > form {
    > p {
      margin-top: 100px;
      margin-bottom: 40px;
  
      max-width: 448px;
      text-align: center;
  
      color: #fff;
  
      line-height: 19px;
    }
  
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      
      font-weight: 500;
      font-size: 14px;
  
      margin-top: 40px;
  
      > span {
        color: #fff;
      }
  
      > button {
        color: #A729F5;
  
        border: 0;
        background-color: transparent;
        outline: 0;
      }
    }
  
    > button {
      max-width: 358px;
      width: 100%;
  
      height: 54px;
  
      border-radius: 8px;
  
      border: 0;
      outline: 0;
  
      background: linear-gradient(271.14deg, #7229F5 1.5%, #0A3FD6 98.6%);
  
      margin-top: 56px;
  
      font-size: 18px;
      font-weight: 600;
  
      color: #fff;
  
      &:disabled {
        opacity: .3;
        cursor: not-allowed;
      }
    }
  
    > span {
      color: tomato;
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}