@use '../../styles/global' as *;
@use '../../styles//partials/variables' as *;

.users {
    @extend .general-tab;

    &__top {
        @extend .tab-top;

        &-heading {
            @extend .page-headings;
        }

        &-buttons {
            display: flex;
            align-items: center;

            &-search {
                @extend .search;

                &-icon {
                    @extend .search-icon;
                }

                &-input {
                    @extend .search-input;
                }

                &-x {
                    @extend .search-x;

                    &-text {
                        @extend .search-x-text;
                    }
                }
            }

            &-colorful {
                @extend .colorful-button;

                margin-right: 2vw;
            }

            &-clear {
                @extend .clear-button;

                &-icon {
                    padding-left: 1vw;
                }
            }
        }
    }

    &__sorts {
        margin-bottom: 2vw;

        display: flex;

        &-button {
            @extend .sort-buttons;

            &-text {
                @extend .sort-buttons-text;
            }

            &-icon {
                padding-left: .8vw;
            }
        }

        &-purple {
            background-color: transparent;
            border: none;

            color: $button-purple;

        }
    }

    &__table {
        width: 100%;
        border-collapse: collapse;

        &-top {

            th {

                &:nth-child(1),
                &:nth-child(5) {
                    width: 13%;
                }

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    width: 8%;
                }

                &:nth-child(6),
                &:nth-child(9),
                &:nth-child(10) {
                    width: 10%;
                }

                &:nth-child(7),
                &:nth-child(8) {
                    width: 15%;
                }
            }

            &-headings {

                &-heading {
                    @extend .table-headings;
                }
            }
        }

        &-info {

            &-info {
                border-bottom: $table-info-bottom;

                &:hover {
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
                }

                &-text {
                    @extend .table-info;

                    &-icon {
                        margin-left: .5vw;

                        width: .9vw;
                        height: .9vw;

                        opacity: 0;
                    }

                    &-link {
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                &:hover &-text-icon {
                    opacity: 1;
                }
            }

        }
    }
}