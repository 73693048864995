@use "../../styles/partials/variables" as *;

.topnav {
  height: 80px;
  width: 100%;
  position: absolute;

  background: linear-gradient(
    90deg,
    $background-purple 0%,
    $background-black 100%
  );
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 52px;
  padding-right: 32px;

  &__logo {
    height: auto;
    max-width: 145px;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 30%;
  }

  &__signout {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: rgba($white, 0.1);
    border-radius: 20px;

    width: max-content;
    padding: 0.8vw 1vw;

    position: absolute;
    right: 3vw;
    top: 3.5vw;
    z-index: 2;

    cursor: pointer;

    gap: 10px;

    &-left {
      display: flex;
      align-items: center;

      &-text {
        margin-left: 0.3vw;

        color: #fff;
      }
    }
  }
}
