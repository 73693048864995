@use "../../styles/partials/variables" as *;
@use "../../styles/global" as *;

.settings {
  @extend .general-tab;

  height: auto;

  &__heading {
    @extend .page-headings;
    margin-bottom: 2vw;
  }

  &__content {
    width: 100%;

    display: flex;

    &-nav {
      width: 30%;
      height: 40vw;
      display: flex;
      flex-direction: column;

      border-right: 1px solid rgba($white, 0.1);

      &-link {
        width: 95%;

        display: flex;
        justify-content: space-between;
        align-items: center;

        text-decoration: none;
        padding: 1vw 0.5vw 1vw 2vw;
        margin-bottom: 0.5vw;

        color: $white;

        &--right {
          display: flex;
          align-items: center;
        }

        &-img {
          width: 1.7vw;
          height: 1.7vw;

          margin-right: 1vw;
        }

        &-text {
          font-size: 1.1rem;
          font-weight: 300;
        }
      }
    }

    &-outlet {
      width: 70%;
    }
  }
}
