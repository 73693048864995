@use "../../styles/partials/variables" as *;
@use "../../styles/global" as *;

.edit-user {
  @extend .general-tab;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1vw 2vw 1vw 1vw;

    &-left {
      display: flex;
      align-items: center;

      &-icon {
        width: 2vw;
        height: 2vw;

        margin-right: 1.5vw;
      }

      &-heading {
        @extend .page-headings;
      }
    }

    &-button {
      display: flex;
      align-items: center;

      gap: 10px;

      background-color: transparent;
      color: $white;
      font-size: 1.3rem;

      border-radius: 8px;
      border: 2px solid $white;

      padding: 1vw 2vw;
    }
  }

  &__form {
    width: 100%;

    display: flex;

    &-first {
      display: flex;
      flex-direction: column;
      gap: 32px;

      width: 100%;

      > div {
        display: flex;
        align-items: center;
        gap: 20px;
        > div {
          width: 100%;
        }
      }

      &-field {
        display: flex;
        flex-direction: column;

        width: 45%;

        margin-bottom: 2vw;

        color: $white;

        &-label {
          padding: 0.5vw 2vw;
        }

        &-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &-input {
          background-color: rgba($white, 0.1);
          color: $white;

          outline: none;
          border: none;
          border-radius: 8px;

          padding: 1vw 2vw;
          width: 100%;

          &:focus {
            border: 1px solid $general-purple;
          }

          &-calendar {
            width: 100%;
            position: relative;
          }

          &--calendar {
            margin-left: 2vw;

            &:hover {
              cursor: pointer;
            }
          }

          &--dropdown {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }

    &-button {
      background: linear-gradient(90deg, $button-purple 0%, $button-blue 100%);

      border: none;
      border-radius: 8px;

      display: flex;

      padding: 1vw 2vw;

      max-width: max-content;

      gap: 10px;

      &-text {
        color: $white;
        font-size: 1rem;
      }
    }
  }
}

// Selectors for the dropdown list of autocompletion for location
.pac {
  &-container {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border: none;
    max-height: 200px; /* Set a maximum height for the dropdown */
    overflow-y: auto; /* Enable vertical scrolling if needed */

    &::-webkit-scrollbar {
      width: 10px; /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1); /* Color of the track */
      border-radius: 10px; /* Rounded corners of the track */
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.5); /* Color of the scrollbar thumb */
      border-radius: 10px; /* Rounded corners of the thumb */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 0.6); /* Color of the thumb on hover */
    }
  }

  &-item {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border-color: rgba(255, 255, 255, 0.2);

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

