@use "../../styles/partials/variables" as *;
@use "../../styles/global" as *;

.delete-modal {
  width: 100vw;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  background-color: rgba($background-black, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;

  color: $white;

  &__modal {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);

    width: 30vw;
    height: fit-content;

    &-top {
      display: flex;
      justify-content: space-between;

      padding: 1vw 0.5vw;

      border-bottom: 0.5px solid $grey;
    }

    &-content {
      padding: 2vw;

      &-text {
        font-size: 1.5rem;
        text-align: center;

        margin-bottom: 1vw;
      }
    }

    &-buttons {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-bottom: 2vw;

      &-clear {
        @extend .clear-button;

        margin-right: 1vw;
      }

      &-colorful {
        @extend .colorful-button;
      }
    }
  }
}

.container-buttons-modal {
  display: flex;

  justify-content: center;
  align-items: center;

  padding: 20px 0;

  gap: 10px;

  > button:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: transparent;
    color: #ffffff;
    border-radius: 8px;
    border: 2px solid #ffffff;
    padding: 1vw 2vw;

    font-size: 12px;
  }
  > button:nth-child(2) {
    background: linear-gradient(90deg, #7229f5 0%, #0a3fd6 100%);
    border: 1px solid transparent;
    border-radius: 8px;
    display: flex;
    padding: 1vw 2vw;
    max-width: max-content;
    gap: 10px;

    font-size: 12px;

    color: #fff;
  }
}
