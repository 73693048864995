@use "../../styles/global" as *;
@use "../../styles//partials/variables" as *;

.liquor {
  @extend .general-tab;

  color: $white;

  &__top {
    @extend .tab-top;

    &-heading {
      @extend .page-headings;
    }

    &-buttons {
      display: flex;
      align-items: center;

      &-colorful {
        @extend .colorful-button;

        margin-right: 2vw;
      }

      &-clear {
        @extend .clear-button;

        &-icon {
          padding-left: 1vw;
        }
      }
    }
  }
}
